import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import GlobalMixin from "./mixins/GlobalMixin";
Vue.use(VueAxios, axios, Vuetify);
Vue.mixin(GlobalMixin)
axios.defaults.baseURL = "https://appship.chekly.com/api/v1/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/v1/";
Vue.config.productionTip = false;

import "@/styles/main.scss";
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
