export default {
  name: "GlobalMixin",
  data() {
    return {};
  },
  // watch: {
  //   $route(e) {
  //     let name = e.name
  //     let page_key = this.$store.state.static_translations.find((e) => e.page == name);
  //     if(!page_key && name != "translations") {
  //       this.get_Static_Translate(e.name);
  //     }
  //   },
  // },
  methods: {
    requirements() {
      if (this.$store.getters.loggedin)
        this.$store
          .dispatch("public__request", {
            config: {
              url: `company/shipments_requirements`,
              method: "get",
            },
          })
          .then((res) => {
            this.$store.commit("store_items", {
              items: "containers",
              data: res.data.data.containers,
            });

            this.$store.commit("store_items", {
              data: res.data.data.currencies,
              items: "currencies",
            });

            this.$store.commit("store_items", {
              data: res.data.data.countries,
              items: "countries",
            });

            this.$store.commit("store_items", {
              data: res.data.data.deliveries,
              items: "deliveries",
            });
          });
    },
  },
};
