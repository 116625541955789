import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";
const login = () => import("@/views/Login.vue");

//---Start Company
const dashboard = () => import("@/views/Dashboard");
const categories = () => import("@/views/categories");
const customers = () => import("@/views/customers/customers");
const CustomersRoutes = () => import("@/views/customers/CustomersRoutes");
const customer = () => import("@/views/customers/customer");
const customerReport = () => import("@/views/customers/report");
const shipments = () => import("@/views/shipments/Shipments");
const shipmentsTable = () => import("@/views/shipments/ShipmentsTable");
const ShipmentForm = () => import("@/views/shipments/ShipmentForm");
const payments = () => import("@/views/payments");
const invoices = () => import("@/views/Invoices");
const currencies = () => import("@/views/currencies");
const containers = () => import("@/views/containers");
const countries = () => import("@/views/countries");
const transfers = () => import("@/views/Transfers");
const deliveries = () => import("@/views/deliveries");
const Delivery = () => import("@/views/Delivery");
const SuppliersRoute = () => import("@/views/suppliers/SuppliersRoute");
const suppliers = () => import("@/views/suppliers/suppliers");
const settings = () => import("@/views/settings");
const smsMsg = () => import("@/views/smsMsg");
const supplier = () => import("@/views/suppliers/supplier");
const CustomerLocation = () => import("@/views/CustomerLocation");
//----------- End Company

//---Start Super_admin
const companies = () => import("@/views/super_admin/companies");
//---End Super admin

const problem = () => import("@/views/problem");
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      title: "dashboard",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/countries",
    name: "countries",
    component: countries,
    meta: {
      title: "countries",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/containers",
    name: "containers",
    component: containers,
    meta: {
      title: "containers",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/transfers",
    name: "transfers",
    component: transfers,
    meta: {
      title: "transfers",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/currencies",
    name: "currencies",
    component: currencies,
    meta: {
      title: "currencies",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/deliveries",
    name: "deliveries",
    component: deliveries,
    meta: {
      title: "deliveries",
      requiresAuth: true,
      super_admin: true,
    },
  },

  {
    path: "/delivery/:id",
    name: "delivery",
    component: Delivery,
    meta: {
      title: "delivery",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: payments,
    meta: {
      title: "payments",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: invoices,
    meta: {
      title: "invoices",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/suppliers",
    component: SuppliersRoute,
    meta: {
      title: "suppliers",
      requiresAuth: true,
      super_admin: false,
    },
    children: [
      {
        path: "/",
        name: "suppliers",
        component: suppliers,
        meta: {
          title: "suppliers",
        },
      },
      {
        path: ":id",
        name: "supplier",
        component: supplier,
        meta: {
          title: "supplier",
        },
      },
    ],
  },
  {
    path: "/companies",
    name: "companies",
    component: companies,
    meta: {
      title: "companies",
      requiresAuth: true,
      super_admin: true,
    },
  },
  {
    path: "/customers",
    component: CustomersRoutes,
    meta: {
      requiresAuth: true,
      super_admin: false,
      title: "companies",
    },
    children: [
      {
        path: "/",
        name: "customers",
        component: customers,
        meta: {
          requiresAuth: true,
          title: "customers",
        },
      },
      {
        path: ":uuid",
        name: "customer",
        component: customer,
        meta: {
          requiresAuth: true,
          title: "customer",
        },
      },
    ],
  },
  {
    path: "/customerReport",
    name: "customerReport",
    component: customerReport,
    meta: {
      title: "report",
      requiresAuth: false,
    },
  },
  {
    path: "/shipments",
    component: shipments,
    title: "shipments",
    meta: {
      title: "shipments",
      requiresAuth: true,
      super_admin: false,
    },
    children: [
      {
        path: "/",
        name: "shipments",
        component: shipmentsTable,

        meta: {
          title: "shipments",
          requiresAuth: true,
        },
      },
      {
        path: "shipment/:uuid?",
        name: "ShipmentForm",
        component: ShipmentForm,
        meta: {
          title: "shipment",
          requiresAuth: true,
        },
      },
    ],
  },

  //--------------- Sections
  {
    path: "/smsMsg",
    name: "smsMsg",
    component: smsMsg,
    meta: {
      title: "smsMsg",
      requiresAuth: true,
      super_admin: false,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
      super_admin: false,
    },
  },

  //--------------- Sections
  {
    path: "/categories",
    name: "categories",
    component: categories,
    meta: {
      title: "categories",
      requiresAuth: true,
      super_admin: false,
    },
  },

  //--------------- public
  {
    path: "/CustomerLocation/:id",
    name: "CustomerLocation",
    component: CustomerLocation,
    meta: {
      title: "CustomerLocation",
      requiresAuth: false,
    },
  },

  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "/problem",
    name: "problem",
    component: problem,
    meta: {
      title: "problem",
      requiresAuth: false,
    },
  },
  {
    path: "*",
    name: "notfound",
    component: notfound,
    meta: {
      title: "notfound",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;

  if (to.name != "notfound") {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next();
      } else {
        next();
      }
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next({
          name: "login",
        });
      } else {
        next();
      }
    }
  }
});
