<template>
  <div class="header mb-5">
    <v-app-bar
      :app="$store.state.break_point <= 991"
      color="white"
      flat
      height="65"
    >
      <v-container class="py-0 align-center d-flex justify-space-between">
        <h1></h1>
        <v-btn
          v-if="$store.state.break_point <= 991 || $route.meta.requiresAuth"
          small
          icon
          @click="$store.state.drawer = !$store.state.drawer"
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  methods: {},
};
</script>
